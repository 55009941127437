import React, { Component, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { IntlProvider, addLocaleData } from "react-intl"
import zh from "react-intl/locale-data/zh"
import { ConfigProvider } from 'antd'
import zh_TW from 'antd/lib/locale-provider/zh_TW'
import en_US from 'antd/lib/locale-provider/en_US'
import translations from "./i18n/locales"
import queryString from 'query-string'
import moment from 'moment'

import authStore from './stores/authStore'

import ResetPasswordPage from './containers/ResetPasswordPage'

addLocaleData([...zh])

moment.locale('zh-hk')

const theme = {
    primary: '#FA5C32'
}

class App extends Component {

    render() {
        const params = queryString.parse(window.location.search)
        const locale = params.locale || 'zh'
        const messages = translations[locale]
        return (
            <Provider
                authStore={authStore}
            >
                <IntlProvider locale={locale} messages={messages}>
                    <ConfigProvider locale={locale === 'zh' ? zh_TW : en_US}>
                        <ThemeProvider theme={theme}>
                            <Router>
                                <Switch>
                                    <Route exact path="/" component={ResetPasswordPage} />
                                </Switch>
                            </Router>
                        </ThemeProvider>
                    </ConfigProvider>
                </IntlProvider>
            </Provider>
        )
    }
}

export default App
