import { defineMessages } from 'react-intl'

export default defineMessages({
    companyName: { id: 'companyName', defaultMessage: 'Shopper' },
    copyright: { id: 'copyright', defaultMessage: '版權所有 © Shopper' },
    adminPanel: { id: 'adminPanel', defaultMessage: '管理員平台' },
    password: { id: 'password', defaultMessage: '密碼' },
    newPassword: { id: 'newPassword', defaultMessage: '新密碼' },
    confirmPassword: { id: 'confirmPassword', defaultMessage: '確認密碼' },
    admin: { id: 'admin', defaultMessage: '管理員' },
    user: { id: 'user', defaultMessage: '用戶' },
    username: { id: 'username', defaultMessage: '用戶名' },
    email: { id: 'email', defaultMessage: '電郵' },
    role: { id: 'role', defaultMessage: '角色' },
    createdBy: { id: 'createdBy', defaultMessage: '創建者' },
    createdAt: { id: 'createdAt', defaultMessage: '創建時間' },
    updatedBy: { id: 'updatedBy', defaultMessage: '更新者' },
    updatedAt: { id: 'updatedAt', defaultMessage: '更新時間' },
    name: { id: 'name', defaultMessage: '名稱' },
    avatar: { id: 'avatar', defaultMessage: '頭像' },
    phoneNumber: { id: 'phoneNumber', defaultMessage: '電話號碼' },
    address: { id: 'address', defaultMessage: '地址' },
    adminList: { id: 'adminList', defaultMessage: '管理員列表' },
    createAdmin: { id: 'createAdmin', defaultMessage: '創建管理員' },
    userList: { id: 'userList', defaultMessage: '用戶列表' },
    createUser: { id: 'createUser', defaultMessage: '創建用戶' },
    forgotPassword: { id: 'forgotPassword', defaultMessage: '忘記密碼' },
    resetPassword: { id: 'resetPassword', defaultMessage: '重設密碼' },
    loginPageTitle: { id: 'loginPageTitle', defaultMessage: '登入' },
    forgotPasswordPageTitle: { id: 'forgotPasswordPageTitle', defaultMessage: '忘記密碼' },
    resetPasswordPageTitle: { id: 'resetPasswordPageTitle', defaultMessage: '重設密碼' },
    adminDetailPageTitle: { id: 'adminDetailPageTitle', defaultMessage: '管理員詳情' },
    adminListPageTitle: { id: 'adminListPageTitle', defaultMessage: '管理員列表' },
    createAdminPageTitle: { id: 'createAdminPageTitle', defaultMessage: '創建管理員' },
    userListPageTitle: { id: 'userListPageTitle', defaultMessage: '用戶列表' },
    createUserPageTitle: { id: 'createUserPageTitle', defaultMessage: '創建用戶' },
    actions: { id: 'actions', defaultMessage: '行動' },
    edit: { id: 'edit', defaultMessage: '更改' },
    delete: { id: 'delete', defaultMessage: '刪除' },
    update: { id: 'update', defaultMessage: '更新' },
    login: { id: 'login', defaultMessage: '登入' },
    logout: { id: 'logout', defaultMessage: '登出' },
    submit: { id: 'submit', defaultMessage: '提交' },
    viewUsers: { id: 'viewUsers', defaultMessage: '查看用戶' },
    viewBuildings: { id: 'viewBuildings', defaultMessage: '查看樓宇' },
    addUser: { id: 'addUser', defaultMessage: '新增用戶' },
    addBuilding: { id: 'addBuilding', defaultMessage: '新增樓宇' },
    yes: { id: 'yes', defaultMessage: '是' },
    no: { id: 'no', defaultMessage: '否' },
    deleteReminder: { id: 'deleteReminder', defaultMessage: '確定刪除？' },
    cancelReminder: { id: 'cancelReminder', defaultMessage: '確定取消？' },
    pleaseCheckYourEmail: { id: 'pleaseCheckYourEmail', defaultMessage: '請查收電郵' },
    passwordChangedReminder: { id: 'passwordChangedReminder', defaultMessage: '密碼已更新，請再次登入。' },
    createAdminSuccess: { id: 'createAdminSuccess', defaultMessage: '創建管理員成功' },
    createAdminFailure: { id: 'createAdminFailure', defaultMessage: '創建管理員失敗' },
    updateAdminSuccess: { id: 'updateAdminSuccess', defaultMessage: '更新管理員成功' },
    updateAdminFailure: { id: 'updateAdminFailure', defaultMessage: '更新管理員失敗' },
    createUserSuccess: { id: 'createUserSuccess', defaultMessage: '創建用戶成功' },
    createUserFailure: { id: 'createUserFailure', defaultMessage: '創建用戶失敗' },
    updateUserSuccess: { id: 'updateUserSuccess', defaultMessage: '更新用戶成功' },
    updateUserFailure: { id: 'updateUserFailure', defaultMessage: '更新用戶失敗' },
    pleaseInputUsername: { id: 'pleaseInputUsername', defaultMessage: '請輸入用戶名' },
    pleaseInputName: { id: 'pleaseInputName', defaultMessage: '請輸入名稱' },
    pleaseInputEmail: { id: 'pleaseInputEmail', defaultMessage: '請輸入電郵' },
    pleaseInputPhoneNumber: { id: 'pleaseInputPhoneNumber', defaultMessage: '請輸入電話號碼' },
    pleaseInputPassword: { id: 'pleaseInputPassword', defaultMessage: '請輸入密碼' },
    pleaseInputAddress: { id: 'pleaseInputAddress', defaultMessage: '請輸入地址' },
    pleaseInputYearBuild: { id: 'pleaseInputYearBuild', defaultMessage: '請輸入落成年份' },
    pleaseConfirmPassword: { id: 'pleaseConfirmPassword', defaultMessage: '請確認密碼' },
    confirmPasswordError: { id: 'confirmPasswordError', defaultMessage: '密碼不相符' },
    emailFormatError: { id: 'emailFormatError', defaultMessage: '電郵格式錯誤' },
    usernameFormatError: { id: 'usernameFormatError', defaultMessage: '用戶名格式錯誤' },
    nameFormatError: { id: 'nameFormatError', defaultMessage: '名稱格式錯誤' },
    passwordFormatError: { id: 'passwordFormatError', defaultMessage: '密碼格式錯誤' },
    street: { id: 'street', defaultMessage: '街道' },
    digit: { id: 'digit', defaultMessage: '數字' },
    character: { id: 'character', defaultMessage: '字母' },
    floor: { id: 'floor', defaultMessage: '樓層' },
    room: { id: 'room', defaultMessage: '室號' },
    roomNumber: { id: 'roomNumber', defaultMessage: '室號' },
    region: { id: 'region', defaultMessage: '區域' },
    price: { id: 'price', defaultMessage: '價格' },
    advance: { id: 'advance', defaultMessage: '進階' },
    enquiry: { id: 'enquiry', defaultMessage: '查詢' },
    reset: { id: 'reset', defaultMessage: '重設' },
    ownershipRenewed: { id: 'ownershipRenewed', defaultMessage: '業權更新' },
    informationUpdated: { id: 'informationUpdated', defaultMessage: '資料更新' },
    bookmarked: { id: 'bookmarked', defaultMessage: '已收藏' },
    confirm: { id: 'confirm', defaultMessage: '確定' },
    pleaseInput: { id: 'pleaseInput', defaultMessage: '請輸入' },
    pleaseSelect: { id: 'pleaseSelect', defaultMessage: '請選擇' },
    propertyId: { id: 'propertyId', defaultMessage: '盤源編號' },
    contactPersonPhone: { id: 'contactPersonPhone', defaultMessage: '聯絡人電話' },
    notice: { id: 'notice', defaultMessage: '提示' },
    other: { id: 'other', defaultMessage: '其他' },
    tower: { id: 'tower', defaultMessage: '期座' },
    emptyData: { id: 'emptyData', defaultMessage: '無數據' },
    type: { id: 'type', defaultMessage: '類型' },
    save: { id: 'save', defaultMessage: '儲存' },
    cancel: { id: 'cancel', defaultMessage: '取消' },
    englishName: { id: 'englishName', defaultMessage: '英文名' },
    note: { id: 'note', defaultMessage: '備註' },
    contactPerson: { id: 'contactPerson', defaultMessage: '聯絡人' },
    contactPersonNameInChinese: { id: 'contactPersonNameInChinese', defaultMessage: '聯絡人(中文)' },
    contactPersonNameInEnglish: { id: 'contactPersonNameInEnglish', defaultMessage: '聯絡人(英文)' },
    sex: { id: 'sex', defaultMessage: '性別' },
    phoneNote: { id: 'phoneNote', defaultMessage: '電話備註' },
    male: { id: 'male', defaultMessage: '男' },
    female: { id: 'female', defaultMessage: '女' },
    startTime: { id: 'startTime', defaultMessage: '開始時間' },
    endTime: { id: 'endTime', defaultMessage: '結束時間' },
    shopperMemberTermsAndConditions: { id: 'shopperMemberTermsAndConditions', defaultMessage: 'Shopper 會員條款及細則' },
    copy: { id: 'copy', defaultMessage: '複制' },
    follower: { id: 'follower', defaultMessage: '跟進人' },
    item: { id: 'item', defaultMessage: '項目' },
    district: { id: 'district', defaultMessage: '地區' },
    picture: { id: 'picture', defaultMessage: '圖片' },
    id: { id: 'id', defaultMessage: '序號' },
    addNew: { id: 'addNew', defaultMessage: '新增' },
    search: { id: 'search', defaultMessage: '搜尋' },
    read: { id: 'read', defaultMessage: '查閱' },
    content: { id: 'content', defaultMessage: '內容' },
    detail: { id: 'detail', defaultMessage: '詳情' },
    categoryManagement: { id: 'categoryManagement', defaultMessage: '分類管理' },
    productManagement: { id: 'productManagement', defaultMessage: '商品管理' },
    optionManagement: { id: 'optionManagement', defaultMessage: '選項管理' },
    productList: { id: 'productList', defaultMessage: '商品列表' },
    reservationManagement: { id: 'reservationManagement', defaultMessage: '預約管理' },
    couponManagement: { id: 'couponManagement', defaultMessage: '優惠劵管理' },
    couponList: { id: 'couponList', defaultMessage: '優惠劵列表' },
    staffManagement: { id: 'staffManagement', defaultMessage: '員工管理' },
    advertisementManagement: { id: 'advertisementManagement', defaultMessage: '廣告管理' },
    mainPageAdvertisement: { id: 'mainPageAdvertisement', defaultMessage: '首頁廣告' },
    feedManagement: { id: 'feedManagement', defaultMessage: '動態管理' },
    orderManagement: { id: 'orderManagement', defaultMessage: '訂單管理' },
    status: { id: 'status', defaultMessage: '狀態' },
    active: { id: 'active', defaultMessage: '正常' },
    inactive: { id: 'inactive', defaultMessage: '禁用' },
    createCategory: { id: 'createCategory', defaultMessage: '新增分類' },
    editCategory: { id: 'editCategory', defaultMessage: '更新分類' },
    createCategorySuccess: { id: 'createCategorySuccess', defaultMessage: '創建分類成功' },
    createCategoryFailure: { id: 'createCategoryFailure', defaultMessage: '創建分類失敗' },
    updateCategorySuccess: { id: 'updateCategorySuccess', defaultMessage: '更新分類成功' },
    updateCategoryFailure: { id: 'updateCategoryFailure', defaultMessage: '更新分類失敗' },
    createProduct: { id: 'createProduct', defaultMessage: '新增商品' },
    editProduct: { id: 'editProduct', defaultMessage: '更新商品' },
    createProductSuccess: { id: 'createProductSuccess', defaultMessage: '創建商品成功' },
    createProductFailure: { id: 'createProductFailure', defaultMessage: '創建商品失敗' },
    updateProductSuccess: { id: 'updateProductSuccess', defaultMessage: '更新商品成功' },
    updateProductFailure: { id: 'updateProductFailure', defaultMessage: '更新商品失敗' },
    introduction: { id: 'introduction', defaultMessage: '簡介' },
    productId: { id: 'productId', defaultMessage: '商品編號' },
    rewardCredit: { id: 'rewardCredit', defaultMessage: '反贈積分' },
    description: { id: 'description', defaultMessage: '商品簡介' },
    logisticDescription: { id: 'logisticDescription', defaultMessage: '送貨及退貨安排' },
    uploadPhoto: { id: 'uploadPhoto', defaultMessage: '上傳圖片' },
    recommend: { id: 'recommend', defaultMessage: '推薦' },
    isRecommended: { id: 'isRecommended', defaultMessage: '是否推薦' },
    productType: { id: 'productType', defaultMessage: '商品種類' },
    group: { id: 'group', defaultMessage: '欄目類別' },
    orderedProduct: { id: 'ordeorderedProductrProduct', defaultMessage: '訂購商品' },
    reservedProduct: { id: 'reservedProduct', defaultMessage: '預約商品' },
    petsProduct: { id: 'petsProduct', defaultMessage: '寵物用品' },
    beautyAndHealth: { id: 'beautyAndHealth', defaultMessage: '美容保健' },
    sportAndTravel: { id: 'sportAndTravel', defaultMessage: '運動旅行' },
    fashion: { id: 'fashion', defaultMessage: '時尚服飾' },
    entertainment: { id: 'entertainment', defaultMessage: '吃喝玩樂' },
    supermarket: { id: 'supermarket', defaultMessage: '超級市場' },
    category: { id: 'category', defaultMessage: '商品分類' },
    resetAllSearch: { id: 'resetAllSearch', defaultMessage: '重設所有搜尋' },
    productIdOrName: { id: 'productIdOrName', defaultMessage: '商品編號或名稱' },
    createProductOption: { id: 'createProductOption', defaultMessage: '新增商品選項' },
    editProductOption: { id: 'editProductOption', defaultMessage: '更新商品選項' },
    createProductOptionSuccess: { id: 'createProductOptionSuccess', defaultMessage: '創建商品選項成功' },
    createProductOptionFailure: { id: 'createProductOptionFailure', defaultMessage: '創建商品選項失敗' },
    updateProductOptionSuccess: { id: 'updateProductOptionSuccess', defaultMessage: '更新商品選項成功' },
    updateProductOptionFailure: { id: 'updateProductOptionFailure', defaultMessage: '更新商品選項失敗' },
    stock: { id: 'stock', defaultMessage: '存貨' },
    cost: { id: 'cost', defaultMessage: '成本' },
    userCountMin: { id: 'userCountMin', defaultMessage: '最小使用人數' },
    userCountMax: { id: 'userCountMax', defaultMessage: '最多使用人數' },
    createReservation: { id: 'createReservation', defaultMessage: '新增預約' },
    editReservation: { id: 'editReservation', defaultMessage: '更新預約' },
    createReservationSuccess: { id: 'createReservationSuccess', defaultMessage: '創建預約成功' },
    createReservationFailure: { id: 'createReservationFailure', defaultMessage: '創建預約失敗' },
    updateReservationSuccess: { id: 'updateReservationSuccess', defaultMessage: '更新預約成功' },
    updateReservationFailure: { id: 'updateReservationFailure', defaultMessage: '更新預約失敗' },
    time: { id: 'time', defaultMessage: '時間' },
    sort: { id: 'sort', defaultMessage: '排序' },
    createAdsBanner: { id: 'createAdsBanner', defaultMessage: '新增廣告橫額' },
    editAdsBanner: { id: 'editAdsBanner', defaultMessage: '更新廣告橫額' },
    createAdsBannerSuccess: { id: 'createAdsBannerSuccess', defaultMessage: '創建廣告橫額成功' },
    createAdsBannerFailure: { id: 'createAdsBannerFailure', defaultMessage: '創建廣告橫額失敗' },
    updateAdsBannerSuccess: { id: 'updateAdsBannerSuccess', defaultMessage: '更新廣告橫額成功' },
    updateAdsBannerFailure: { id: 'updateAdsBannerFailure', defaultMessage: '更新廣告橫額失敗' },
    photo: { id: 'photo', defaultMessage: '圖片' },
    photoCount: { id: 'photoCount', defaultMessage: '圖片總數: {count}' },
    title: { id: 'title', defaultMessage: '標題' },
    createFeed: { id: 'createFeed', defaultMessage: '新增動態' },
    editFeed: { id: 'editFeed', defaultMessage: '更新動態' },
    createFeedSuccess: { id: 'createFeedSuccess', defaultMessage: '創建動態成功' },
    createFeedFailure: { id: 'createFeedFailure', defaultMessage: '創建動態失敗' },
    updateFeedSuccess: { id: 'updateFeedSuccess', defaultMessage: '更新動態成功' },
    updateFeedFailure: { id: 'updateFeedFailure', defaultMessage: '更新動態失敗' },
    fromTime: { id: 'fromTime', defaultMessage: '由' },
    toTime: { id: 'toTime', defaultMessage: '至' },
    duration: { id: 'duration', defaultMessage: '耗時' },
    durationMinute: { id: 'durationMinute', defaultMessage: '{duration} 分鐘' },
    createCoupon: { id: 'createCoupon', defaultMessage: '新增優惠劵' },
    editCoupon: { id: 'editCoupon', defaultMessage: '更新優惠劵' },
    createCouponSuccess: { id: 'createCouponSuccess', defaultMessage: '創建優惠劵成功' },
    createCouponFailure: { id: 'createCouponFailure', defaultMessage: '創建優惠劵失敗' },
    updateCouponSuccess: { id: 'updateCouponSuccess', defaultMessage: '更新優惠劵成功' },
    updateCouponFailure: { id: 'updateCouponFailure', defaultMessage: '更新優惠劵失敗' },
    creditRequired: { id: 'creditRequired', defaultMessage: '所需積分' },
    minPriceRequired: { id: 'minPriceRequired', defaultMessage: '最低可使用金額' },
    discount: { id: 'discount', defaultMessage: '折扣' },
    couponTotalAmount: { id: 'discocouponTotalAmountunt', defaultMessage: '優惠劵總數' },
    maxPurchase: { id: 'maxPurchase', defaultMessage: '最多換領次數' },
    termsAndConditions: { id: 'termsAndConditions', defaultMessage: '條款及細則' },
    couponCountUnit: { id: 'couponCountUnit', defaultMessage: '{count} 張' },
    frequencyUnit: { id: 'frequencyUnit', defaultMessage: '{count} 次' },
    shopInformation: { id: 'shopInformation', defaultMessage: '商店訊息' },
    website: { id: 'website', defaultMessage: '網頁' },
    shopLogo: { id: 'shopLogo', defaultMessage: '商標圖案' },
    feedCover: { id: 'feedCover', defaultMessage: '動態標題圖案' },
    searchCover: { id: 'searchCover', defaultMessage: '搜尋圖案' },
    couponCover: { id: 'couponCover', defaultMessage: '優惠劵標題圖案' },
    updateShopInformationSuccess: { id: 'updateShopInformationSuccess', defaultMessage: '更新商店訊息成功' },
    updateShopInformationFailure: { id: 'updateShopInformationFailure', defaultMessage: '更新商店訊息失敗' },
    orderId: { id: 'orderId', defaultMessage: '訂單編號' },
    complete: { id: 'complete', defaultMessage: '已完成' },
    incomplete: { id: 'incomplete', defaultMessage: '未完成' },
    transactionId: { id: 'transactionId', defaultMessage: '交易編號' },
    totalPrice: { id: 'totalPrice', defaultMessage: '總金額' },
    orderDetail: { id: 'orderDetail', defaultMessage: '訂單詳情' },
    reservationDetail: { id: 'reservationDetail', defaultMessage: '預約詳情' },
    contactDetail: { id: 'contactDetail', defaultMessage: '聯絡詳情' },
    product: { id: 'product', defaultMessage: '商品' },
    quantity: { id: 'quantity', defaultMessage: '數量' },
    userCount: { id: 'userCount', defaultMessage: '使用人數' },
    option: { id: 'option', defaultMessage: '選項' },
    updateOrderSuccess: { id: 'updateOrderSuccess', defaultMessage: '更新訂單成功' },
    updateOrderFailure: { id: 'updateOrderFailure', defaultMessage: '更新訂單失敗' },
    createStaff: { id: 'createStaff', defaultMessage: '新增員工' },
    editStaff: { id: 'editStaff', defaultMessage: '更新員工' },
    createStaffSuccess: { id: 'createStaffSuccess', defaultMessage: '創建員工成功' },
    createStaffFailure: { id: 'createStaffFailure', defaultMessage: '創建員工失敗' },
    updateStaffSuccess: { id: 'updateStaffSuccess', defaultMessage: '更新員工成功' },
    updateStaffFailure: { id: 'updateStaffFailure', defaultMessage: '更新員工失敗' },
    shopOwner: { id: 'shopOwner', defaultMessage: '商店管理員' },
    staff: { id: 'staff', defaultMessage: '員工' },
    memberList: { id: 'memberList', defaultMessage: '會員列表' },
    credit: { id: 'credit', defaultMessage: '積分' },
    joinedAt: { id: 'joinedAt', defaultMessage: '入會日期' },
    membershipStatus: { id: 'membershipStatus', defaultMessage: '會員狀態' },
    resetPasswordSuccess: { id: 'resetPasswordSuccess', defaultMessage: '重設密碼成功' },
    resetPasswordFailure: { id: 'resetPasswordFailure', defaultMessage: '重設密碼失敗' },
    showTotalDisplayText: { id: 'showTotalDisplayText', defaultMessage: '共{total}條' },
    reservationOption: { id: 'reservationOption', defaultMessage: '預約選項' },
    shopDescription: { id: 'shopDescription', defaultMessage: '商舖簡介' },
    priceErrorReminder: { id: 'priceErrorReminder', defaultMessage: '最低價格為5元或免費(0元)' },
    passwordLengthError: { id: 'passwordLengthError', defaultMessage: '密碼長度至少為4個字元' },
    wrongPassword: { id: 'wrongPassword', defaultMessage: '密碼錯誤' },
    productIdDuplicated: { id: 'productIdDuplicated', defaultMessage: '商品編號重覆' },
    categoryNameDuplicated: { id: 'categoryNameDuplicated', defaultMessage: '分類名稱重覆' },
    initial: { id: 'initial', defaultMessage: '商店字首號碼' },
    emailDuplicated: { id: 'emailDuplicated', defaultMessage: '電郵已被使用' },
    createReservationGroup: { id: 'createReservationGroup', defaultMessage: '新增重覆性預約' },
    createReservationGroupSuccess: { id: 'createReservationGroupSuccess', defaultMessage: '新增重覆性預約成功' },
    createReservationGroupFailure: { id: 'createReservationGroupFailure', defaultMessage: '新增重覆性預約失敗' },
    period: { id: 'period', defaultMessage: '日期範圍' },
    week: { id: 'week', defaultMessage: '週' },
    sunday: { id: 'sunday', defaultMessage: '日' },
    monday: { id: 'monday', defaultMessage: '一' },
    tuesday: { id: 'tuesday', defaultMessage: '二' },
    wednesday: { id: 'wednesday', defaultMessage: '三' },
    thursday: { id: 'thursday', defaultMessage: '四' },
    friday: { id: 'friday', defaultMessage: '五' },
    saturday: { id: 'saturday', defaultMessage: '六' },
    branch: { id: 'branch', defaultMessage: '分店' },
    date: { id: 'date', defaultMessage: '日期' },
    pospalTickets: { id: 'pospalTickets', defaultMessage: ' 線下營業記錄' },
    cashierName: { id: 'cashierName', defaultMessage: '收銀員名稱' },
    cashierId: { id: 'cashierId', defaultMessage: '收銀員號碼' },
    checkedOutAt: { id: 'checkedOutAt', defaultMessage: '開單時間' },
    totalAmount: { id: 'totalAmount', defaultMessage: '總金額' },
    dailyTotalAmount: { id: 'dailyTotalAmount', defaultMessage: '全日總金額' },
    itemList: { id: 'itemList', defaultMessage: '商品列表' },
    pospalManagement: { id: 'pospalManagement', defaultMessage: '線下管理' },
    pospalProduct: { id: 'pospalProduct', defaultMessage: '線下商品列表' },
    pospalCategory: { id: 'pospalCategory', defaultMessage: '線下商品分類' },
    pospalOptionGroup: { id: 'pospalOptionGroup', defaultMessage: '線下選項組' },
    subcategory: { id: 'subcategory', defaultMessage: '子分類' },
    createSubcategory: { id: 'createSubcategory', defaultMessage: '新增子分類' },
    createSubcategorySuccess: { id: 'createSubcategorySuccess', defaultMessage: '新增子分類成功' },
    createSubcategoryFailure: { id: 'createSubcategoryFailure', defaultMessage: '新增子分類失敗' },
    editSubcategory: { id: 'editSubcategory', defaultMessage: '更改子分類' },
    editSubcategorySuccess: { id: 'editSubcategorySuccess', defaultMessage: '更改子分類成功' },
    editSubcategoryFailure: { id: 'editSubcategoryFailure', defaultMessage: '更改子分類失敗' },
    deleteCategorySuccess: { id: 'deleteCategorySuccess', defaultMessage: '刪除分類成功' },
    deleteCategoryFailure: { id: 'deleteCategoryFailure', defaultMessage: '刪除分類失敗' },
    subcategoryCount: { id: 'subcategoryCount', defaultMessage: '子分類數量' },
    createOptionGroup: { id: 'createOptionGroup', defaultMessage: '新增選項組' },
    createOptionGroupSuccess: { id: 'createOptionGroupSuccess', defaultMessage: '新增選項組成功' },
    createOptionGroupFailure: { id: 'createOptionGroupFailure', defaultMessage: '新增選項組失敗' },
    editOptionGroup: { id: 'editOptionGroup', defaultMessage: '更改選項組' },
    editOptionGroupSuccess: { id: 'editOptionGroupSuccess', defaultMessage: '更改選項組成功' },
    editOptionGroupFailure: { id: 'editOptionGroupFailure', defaultMessage: '更改選項組失敗' },
    deleteOptionGroupSuccess: { id: 'deleteOptionGroupSuccess', defaultMessage: '刪除選項組成功' },
    deleteOptionGroupFailure: { id: 'deleteOptionGroupFailure', defaultMessage: '刪除選項組失敗' },
    barcode: { id: 'barcode', defaultMessage: '條碼' },
    buyPrice: { id: 'buyPrice', defaultMessage: '進口價' },
    sellPrice: { id: 'sellPrice', defaultMessage: '銷售價' },
    memberPrice: { id: 'memberPrice', defaultMessage: '會員價' },
    memberDiscount: { id: 'memberDiscount', defaultMessage: '會員折扣' },
    wholesellPrice: { id: 'wholesellPrice', defaultMessage: '批發價' },
    deleteProductSuccess: { id: 'deleteProductSuccess', defaultMessage: '刪除商品成功' },
    deleteProductFailure: { id: 'deleteProductFailure', defaultMessage: '刪除商品失敗' },
    confirmed: { id: 'confirmed', defaultMessage: '已確認' },
    pending: { id: 'pending', defaultMessage: '處理中' },
    import: { id: 'import', defaultMessage: '導入' },
    export: { id: 'export', defaultMessage: '導出' },
    file: { id: 'file', defaultMessage: '檔案' },
    importFileReminder: { id: 'importFileReminder', defaultMessage: '檔案格式需為 .xlsx' },
    willExistingProductsBeUpdated: { id: 'willExistingProductsBeUpdated', defaultMessage: '已存在的商品是否更新' },
    mustInput: { id: 'mustInput', defaultMessage: '必填' },
    downloadTemplateReminder: { id: 'downloadTemplateReminder', defaultMessage: '如沒有模板，請先下載' },
    downloadTemplate: { id: 'downloadTemplate', defaultMessage: '下載模板' },
    excelFormatError: { id: 'excelFormatError', defaultMessage: '格式錯誤' },
    exportProductReminder: { id: 'exportProductReminder', defaultMessage: '最少選取一個商品' },
    selectedCount: { id: 'selectedCount', defaultMessage: '(已選取{count}個商品)' },
    categoryNameNotFound: { id: 'categoryNameNotFound', defaultMessage: '分類不存在' },
    rowErrorWithMessages: { id: 'rowErrorWithMessages', defaultMessage: '第{row}行，{field}，{message}' },
    pospalMemberList: { id: 'pospalMemberList', defaultMessage: '線下會員列表' },
    memberTier: { id: 'memberTier', defaultMessage: '會員等級' },
    createPospalMember: { id: 'createPospalMember', defaultMessage: '新增線下會員' },
    createPospalMemberSuccess: { id: 'createPospalMemberSuccess', defaultMessage: '新增線下會員成功' },
    createPospalMemberFailure: { id: 'createPospalMemberFailure', defaultMessage: '新增線下會員失敗' },
    updatePospalMember: { id: 'updatePospalMember', defaultMessage: '更改線下會員' },
    updatePospalMemberSuccess: { id: 'updatePospalMemberSuccess', defaultMessage: '更改線下會員成功' },
    updatePospalMemberFailure: { id: 'updatePospalMemberFailure', defaultMessage: '更改線下會員失敗' },
    balance: { id: 'balance', defaultMessage: '結餘' },
    phoneNumberFormatReminder: { id: 'phoneNumberFormatReminder', defaultMessage: '電話號碼需為香港地區' },
    pospalTicketPrint: { id: 'pospalTicketPrint', defaultMessage: ' 線下單據列印' },
    invoicePreview: { id: 'invoicePreview', defaultMessage: '單據預覽' },
    invoice: { id: 'invoice', defaultMessage: '單據' },
    invoiceId: { id: 'invoiceId', defaultMessage: '單據編號' },
    download: { id: 'download', defaultMessage: '下載' },
    orderDate: { id: 'orderDate', defaultMessage: '開單日期' },
    exportMemberReminder: { id: 'exportMemberReminder', defaultMessage: '最少選取一個會員' },
    selectedMemberCount: { id: 'selectedMemberCount', defaultMessage: '(已選取{count}個會員)' },
    link: { id: 'link', defaultMessage: '連結' },
    linkShopperMember: { id: 'linkShopperMember', defaultMessage: '連結Shopper會員' },
    selectShopperMember: { id: 'selectShopperMember', defaultMessage: '選擇Shopper會員' },
    changeShopperMember: { id: 'changeShopperMember', defaultMessage: '更改Shopper會員' },
    shopperUserHasBeenLinked: { id: 'shopperUserHasBeenLinked', defaultMessage: 'Shopper會員已被連結' },
    unlink: { id: 'unlink', defaultMessage: '解除連結' },
    print: { id: 'print', defaultMessage: '列印' },
    emailSentSuccess: { id: 'emailSentSuccess', defaultMessage: '發送電郵成功' },
    emailSentFailure: { id: 'emailSentFailure', defaultMessage: '發送電郵失敗' },
    selectAll: { id: 'selectAll', defaultMessage: '全部選擇' },
    unselectAll: { id: 'unselectAll', defaultMessage: '取消選擇' },
    bannerRatioReminder: { id: 'bannerRatioReminder', defaultMessage: '圖片比例為16比9, 格式為PNG或JPG' },
    squareRatioReminder: { id: 'squareRatioReminder', defaultMessage: '圖片比例為1比1, 格式為PNG或JPG' },
    noOption: { id: 'noOption', defaultMessage: '沒有選項' },
    linkShopperProductOption: { id: 'linkShopperProductOption', defaultMessage: '連結Shopper商品選項' },
    linkShopperProductOptionSuccess: { id: 'linkShopperProductOptionSuccess', defaultMessage: '連結Shopper商品選項成功' },
    linkShopperProductOptionFailure: { id: 'linkShopperProductOptionFailure', defaultMessage: '連結Shopper商品選項失敗' },
    stockTake: { id: 'stockTake', defaultMessage: '盤點' },
    pospalProductItem: { id: 'pospalProductItem', defaultMessage: '線下商品' },
    shopperProduct: { id: 'shopperProduct', defaultMessage: 'Shopper商品' },
    shopperProductOption: { id: 'shopperProductOption', defaultMessage: 'Shopper商品選項' },
    unlinkReminder: { id: 'unlinkReminder', defaultMessage: '確定解除連結?' },
    unlinkSuccess: { id: 'unlinkSuccess', defaultMessage: '解除連結成功' },
    unlinkFailure: { id: 'unlinkFailure', defaultMessage: '解除連結失敗' },
    stockTakeSuccess: { id: 'stockTakeSuccess', defaultMessage: '盤點成功' },
    stockTakeFailure: { id: 'stockTakeFailure', defaultMessage: '盤點失敗' },
    shopperProductOptionHasBeenLinkedPleaseSelectAnother: { id: 'shopperProductOptionHasBeenLinkedPleaseSelectAnother', defaultMessage: '該Shopper商品選項已經被連結，請再選擇' },
    optionName: { id: 'optionName', defaultMessage: '選項名稱' },
    deliveryMethod: { id: 'deliveryMethod', defaultMessage: '送貨方法' },
    pickUpStore: { id: 'pickUpStore', defaultMessage: '取貨地點' },
    sfexpress: { id: 'sfexpress', defaultMessage: '順豐' },
    contactSeller: { id: 'contactSeller', defaultMessage: '聯絡商店' },
    selfPickUp: { id: 'selfPickUp', defaultMessage: '自取' },
    shopType: { id: 'shopType', defaultMessage: '商舖類別' },
    retail: { id: 'retail', defaultMessage: '零售' },
    restaurant: { id: 'restaurant', defaultMessage: '餐飲' },
    inactiveWithCount: { id: 'inactiveWithCount', defaultMessage: '禁用(已選{count}個)' },
    inactiveReservationSuccess: { id: 'inactiveReservationSuccess', defaultMessage: '禁用預約成功' },
    inactiveReservationFailure: { id: 'inactiveReservationFailure', defaultMessage: '禁用預約失敗' },
    memberOverview: { id: 'memberOverview', defaultMessage: '會員概覽' },
    memberAnalysis: { id: 'memberAnalysis', defaultMessage: '會員分析' },
    memberCount: { id: 'memberCount', defaultMessage: '會員數量' },
    memberTotalCount: { id: 'memberTotalCount', defaultMessage: '會員總數量' },
    today: { id: 'today', defaultMessage: '今天' },
    yesterday: { id: 'yesterday', defaultMessage: '昨天' },
    thisWeek: { id: 'thisWeek', defaultMessage: '本週' },
    thisMonth: { id: 'thisMonth', defaultMessage: '本月' },
    customize: { id: 'customize', defaultMessage: '自選' },
    numberOfOrder: { id: 'numberOfOrder', defaultMessage: '訂單數量' },
    orderTotalAmount: { id: 'orderTotalAmount', defaultMessage: '訂單總金額' },
    newMemberCountByMonth: { id: 'newMemberCountByMonth', defaultMessage: '按月新會員數量' },
    memberTotalCountByMonth: { id: 'memberTotalCountByMonth', defaultMessage: '按月會員總數量' },
    newMemberCount: { id: 'newMemberCount', defaultMessage: '新會員數量' },
    orderTotalAmountByMonth: { id: 'orderTotalAmountByMonth', defaultMessage: '按月訂單總金額' },
    dataAnalysis: { id: 'dataAnalysis', defaultMessage: '數據分析' },
    saleAnalysis: { id: 'saleAnalysis', defaultMessage: '銷售分析' },
    orderCountByMonth: { id: 'orderCountByMonth', defaultMessage: '按月訂單數' },
    orderCount: { id: 'orderCount', defaultMessage: '訂單數' },
    memberBehaviorAnalysis: { id: 'memberBehaviorAnalysis', defaultMessage: '會員行為分析' },
    topBookmarkedProduct: { id: 'topBookmarkedProduct', defaultMessage: '收藏次數最多商品' },
    rank: { id: 'rank', defaultMessage: '名次' },
    bookmarkCount: { id: 'bookmarkCount', defaultMessage: '收藏次數' },
    productName: { id: 'productName', defaultMessage: '商品名稱' },
    mostOrderMember: { id: 'mostOrderMember', defaultMessage: '最多訂單會員' },
    mostAmountMember: { id: 'mostAmountMember', defaultMessage: '最高訂單金額會員' },
    addCategoryErrorReminder: { id: 'addCategoryErrorReminder', defaultMessage: "請先新增分類 '{category}'" },
    groupErrorReminder: { id: 'groupErrorReminder', defaultMessage: "錯誤欄目類別 '{group}'" },
    createProductPhotos: { id: 'createProductPhotos', defaultMessage: '上載商品圖片' },
    confirmCreateProductPhotos: { id: 'confirmCreateProductPhotos', defaultMessage: '確認上載 {count} 張商品圖片?' },
    batchCreatePhotos: { id: 'batchCreatePhotos', defaultMessage: '批量新增圖片' },
    batchCreatePhotosSuccess: { id: 'batchCreatePhotosSuccess', defaultMessage: '批量新增圖片成功' },
    batchCreatePhotosFailure: { id: 'batchCreatePhotosFailure', defaultMessage: '批量新增圖片失敗' },
    importProductImageReminder: { id: 'importProductImageReminder', defaultMessage: '檔案格式需為 xxx.jpg (xxx為商品編號)' },
    selectedImageWithCount: { id: 'selectedImageWithCount', defaultMessage: '已選取{count}圖片' },
    uploadImageDirectory: { id: 'uploadImageDirectory', defaultMessage: '上存圖片文件夾' },
    importProduct: { id: 'importProduct', defaultMessage: '導入' },
    importProductSuccess: { id: 'importProductSuccess', defaultMessage: '導入商品成功' },
    importProductFailure: { id: 'importProductFailure', defaultMessage: '導入商品失敗' },
    productAndImageCountNotMatch: { id: 'productAndImageCountNotMatch', defaultMessage: '商品數目和圖片數目不相符' },
    productImageNotFound: { id: 'productImageNotFound', defaultMessage: '商品圖片不存在' },
    importing: { id: 'importing', defaultMessage: '導入中' },
    importedWithCountAndTotal: { id: 'importedWithCountAndTotal', defaultMessage: '已導入{count}, 共{total}' },
    imported: { id: 'imported', defaultMessage: '導入已完成' },
    trackingNumber: { id: 'trackingNumber', defaultMessage: '追蹤號碼' },
    free: { id: 'free', defaultMessage: '免費' },
    cashVoucher: { id: 'cashVoucher', defaultMessage: '現金卷' },
    itemDiscount: { id: 'itemDiscount', defaultMessage: '單品折扣' },
    optionChoice: { id: 'optionChoice', defaultMessage: '選項選擇' },
    priceAdjustment: { id: 'priceAdjustment', defaultMessage: '價格調整' },
    stockManagement: { id: 'stockManagement', defaultMessage: '存貨管理' },
    deleteSuccess: { id: 'deleteSuccess', defaultMessage: '刪除成功' },
    deleteFailure: { id: 'deleteFailure', defaultMessage: '刪除失敗' },
    createSuccess: { id: 'createSuccess', defaultMessage: '新增成功' },
    createFailure: { id: 'createFailure', defaultMessage: '新增刪除失敗' },
    createProductOptionChoice: { id: 'createProductOptionChoice', defaultMessage: '新增商品選項選擇' },
    atLeastTwoProductOptionChoice: { id: 'atLeastTwoProductOptionChoice', defaultMessage: '每一個選項，最少要有兩個選擇' },
    stocksWillBeResetAfterEditingPleaseUpdateStockAfterward: { id: 'stocksWillBeResetAfterEditingPleaseUpdateStockAfterward', defaultMessage: '選項更改後，存貨會被重設。更改選項後，請更新存貨。' },
    couponUsed: { id: 'couponUsed', defaultMessage: '已使用優惠劵' },
    productImageNameDuplicated: { id: 'productImageNameDuplicated', defaultMessage: '商品圖片名稱重覆' },
    notification: { id: 'notification', defaultMessage: '通知' },
    pushNotification: { id: 'pushNotification', defaultMessage: '推送通知' },
    pushNotificationSuccess: { id: 'pushNotificationSuccess', defaultMessage: '推送通知成功' },
    pushNotificationFailure: { id: 'pushNotificationFailure', defaultMessage: '推送通知失敗' },
    createManyCoupons: { id: 'createManyCoupons', defaultMessage: '新增多張優惠劵' },
    minPriceRequiredMustNotLessThanDiscount: { id: 'minPriceRequiredMustNotLessThanDiscount', defaultMessage: '最低可使用金額不可低過折扣' },
    createOption: { id: 'createOption', defaultMessage: '新增選項' },
    createOptionSuccess: { id: 'createOptionSuccess', defaultMessage: '新增選項成功' },
    createOptionFailure: { id: 'createOptionFailure', defaultMessage: '新增選項失敗' },
    updateOptionSuccess: { id: 'updateOptionSuccess', defaultMessage: '更改選項成功' },
    updateOptionFailure: { id: 'updateOptionFailure', defaultMessage: '更改選項失敗' },
    deleteOptionSuccess: { id: 'deleteOptionSuccess', defaultMessage: '刪除選項成功' },
    deleteOptionFailure: { id: 'deleteOptionFailure', defaultMessage: '刪除選項失敗' },
    addNewOption: { id: 'addNewOption', defaultMessage: '新增選項' },
    updateOption: { id: 'updateOption', defaultMessage: '更改選項' },
    updateThisPage: { id: 'updateThisPage', defaultMessage: '更新此頁' },
    onlineCoupon: { id: 'onlineCoupon', defaultMessage: '線上優惠劵' },
    offlineCoupon: { id: 'offlineCoupon', defaultMessage: '線下優惠劵' },
    deleteReservationSuccess: { id: 'deleteReservationSuccess', defaultMessage: '刪除預約成功' },
    deleteReservationFailure: { id: 'deleteReservationFailure', defaultMessage: '刪除預約失敗' },
    deleteFeedSuccess: { id: 'deleteFeedSuccess', defaultMessage: '刪除動態成功' },
    deleteFeedFailure: { id: 'deleteFeedFailure', defaultMessage: '刪除動態失敗' },
    deleteAdsBannerSuccess: { id: 'deleteAdsBannerSuccess', defaultMessage: '刪除廣告橫額成功' },
    deleteAdsBannerFailure: { id: 'deleteAdsBannerFailure', defaultMessage: '刪除廣告橫額失敗' },
    remark: { id: 'remark', defaultMessage: '備註' },
    delivery: { id: 'delivery', defaultMessage: '外賣' },
    cashOnDelivery: { id: 'cashOnDelivery', defaultMessage: '現金到付' },
    food: { id: 'food', defaultMessage: '食品' },
    cancelled: { id: 'cancelled', defaultMessage: '已取消' },
    createdAtMin: { id: 'createdAtMin', defaultMessage: '創建時間(由)' },
    createdAtMax: { id: 'createdAtMax', defaultMessage: '創建時間(至)' },
    startDateMin: { id: 'startDateMin', defaultMessage: '開始時間(由)' },
    startDateMax: { id: 'startDateMax', defaultMessage: '開始時間(至)' },
    endDateMin: { id: 'endDateMin', defaultMessage: '結束時間(由)' },
    endDateMax: { id: 'endDateMax', defaultMessage: '結束時間(至)' },
    joinedAtMin: { id: 'joinedAtMin', defaultMessage: '入會日期(由)' },
    joinedAtMax: { id: 'joinedAtMax', defaultMessage: '入會日期(至)' },
    timeMin: { id: 'timeMin', defaultMessage: '時間(由)' },
    timeMax: { id: 'timeMax', defaultMessage: '時間(至)' },
    appleAppStoreLink: { id: 'appleAppStoreLink', defaultMessage: 'Apple App Store連結' },
    googlePlayStoreLink: { id: 'googlePlayStoreLink', defaultMessage: 'Google Play連結' },
    chainManagement: { id: 'chainManagement', defaultMessage: '連鎖管理' },
    createChain: { id: 'createChain', defaultMessage: '新增連鎖' },
    editChain: { id: 'editChain', defaultMessage: '更新連鎖' },
    createChainSuccess: { id: 'createChainSuccess', defaultMessage: '創建連鎖成功' },
    createChainFailure: { id: 'createChainFailure', defaultMessage: '創建連鎖失敗' },
    updateChainSuccess: { id: 'updateChainSuccess', defaultMessage: '更新連鎖成功' },
    updateChainFailure: { id: 'updateChainFailure', defaultMessage: '更新連鎖失敗' },
    creditExchangeRate: { id: 'creditExchangeRate', defaultMessage: '積分兌換比例' },
    creditExchangeRateReminder: { id: 'creditExchangeRateReminder', defaultMessage: '如果比例為10，即每消費10元，得1分' },
    loginLogo: { id: 'loginLogo', defaultMessage: '登入Logo' },
    loginBackground: { id: 'loginBackground', defaultMessage: '登入背景' },
    domainName: { id: 'domainName', defaultMessage: '域名' },
    restaurantStoreManagemant: { id: 'restaurantStoreManagemant', defaultMessage: '餐廳分店管理' },
    createRestaurantStore: { id: 'createRestaurantStore', defaultMessage: '新增餐廳分店' },
    createRestaurantStoreSuccess: { id: 'createRestaurantStoreSuccess', defaultMessage: '新增餐廳分店成功' },
    createRestaurantStoreFailure: { id: 'createRestaurantStoreFailure', defaultMessage: '新增餐廳分店失敗' },
    updateRestaurantStore: { id: 'updateRestaurantStore', defaultMessage: '更新餐廳分店' },
    updateRestaurantStoreSuccess: { id: 'updateRestaurantStoreSuccess', defaultMessage: '更新餐廳分店成功' },
    updateRestaurantStoreFailure: { id: 'updateRestaurantStoreFailure', defaultMessage: '更新餐廳分店失敗' },
    storeDescription: { id: 'storeDescription', defaultMessage: '分店簡介' },
    deliverRadius: { id: 'deliverRadius', defaultMessage: '送貨半徑' },
    meters: { id: 'meters', defaultMessage: '米' },
    businessHour: { id: 'businessHour', defaultMessage: '營業時間' },
    foodDelivery: { id: 'foodDelivery', defaultMessage: '送餐' },
    foodDeliveryMethods: { id: 'foodDeliveryMethods', defaultMessage: '取餐方法' },
    coordinate: { id: 'coordinate', defaultMessage: '座標' },
    salesReport: { id: 'salesReport', defaultMessage: '銷售報告' },
    totalOrderCount: { id: 'totalOrderCount', defaultMessage: '該月訂單數' },
    onlineSalesReport: { id: 'onlineSalesReport', defaultMessage: '網上銷售平台報告' },
    to: { id: 'to', defaultMessage: '致' },
    currency: { id: 'currency', defaultMessage: '貨幣' },
    hkd: { id: 'hkd', defaultMessage: '港幣' },
    subtotal: { id: 'subtotal', defaultMessage: '小計' },
    downloadReport: { id: 'downloadReport', defaultMessage: '下載報告' },
    selectShop: { id: 'selectShop', defaultMessage: '選擇商店' },
    selectedShop: { id: 'selectedShop', defaultMessage: '已選擇商店' },
    shopManagement: { id: 'shopManagement', defaultMessage: '店舖管理' },
    createShop: { id: 'createShop', defaultMessage: '新增店舖' },
    editShop: { id: 'editShop', defaultMessage: '更新店舖' },
    createShopSuccess: { id: 'createShopSuccess', defaultMessage: '創建店舖成功' },
    createShopFailure: { id: 'createShopFailure', defaultMessage: '創建店舖失敗' },
    updateShopSuccess: { id: 'updateShopSuccess', defaultMessage: '更新店舖成功' },
    updateShopFailure: { id: 'updateShopFailure', defaultMessage: '更新店舖失敗' },
    membership: { id: 'membership', defaultMessage: '會藉' },
    createShopOwner: { id: 'createShopOwner', defaultMessage: '新增商店管理員' },
    createShopOwnerSuccess: { id: 'createShopOwnerSuccess', defaultMessage: '創建商店管理員成功' },
    createShopOwnerFailure: { id: 'createShopOwnerFailure', defaultMessage: '創建商店管理員失敗' },
    shopName: { id: 'shopName', defaultMessage: '商舖名稱' },
    userInformation: { id: 'userInformation', defaultMessage: '用戶信息' },
    initialDuplicated: { id: 'initialDuplicated', defaultMessage: '商店字首號碼已被使用' },
    pospalBranch: { id: 'pospalBranch', defaultMessage: '銀豹線下分店' },
    createPospalBranch: { id: 'createPospalBranch', defaultMessage: '新增銀豹線下分店' },
    createPospalBranchSuccess: { id: 'createPospalBranchSuccess', defaultMessage: '新增銀豹線下分店成功' },
    createPospalBranchFailure: { id: 'createPospalBranchFailure', defaultMessage: '新增銀豹線下分店失敗' },
    editPospalBranch: { id: 'editPospalBranch', defaultMessage: '更改銀豹線下分店' },
    editPospalBranchSuccess: { id: 'editPospalBranchSuccess', defaultMessage: '更改銀豹線下分店成功' },
    editPospalBranchFailure: { id: 'editPospalBranchFailure', defaultMessage: '更改銀豹線下分店失敗' },
    otherShop: { id: 'otherShop', defaultMessage: '其他商舖' },
    memberDetail: { id: 'memberDetail', defaultMessage: '會員詳情' },
    validDate: { id: 'validDate', defaultMessage: '有效日期' },
    shop: { id: 'shop', defaultMessage: '商舖' },
    phoneNumberDuplicated: { id: 'phoneNumberDuplicated', defaultMessage: '電話已使用' },
    shopperDelivery: { id: 'shopperDelivery', defaultMessage: 'SHOPPER外賣' },
    baofoodRestaurant: { id: 'baofoodRestaurant', defaultMessage: '飽福餐廳' },
    initialError: { id: 'initialError', defaultMessage: '商店字首號碼長度必須為3至5個英文字母或數字' },
    mallDomainName: { id: 'mallDomainName', defaultMessage: '商城域名' },
    websiteThemeColor: { id: 'websiteThemeColor', defaultMessage: '網頁主題顏色' },
    uploadDocument: { id: 'uploadDocument', defaultMessage: '上存文件' },
    deleteCategoryReminder: { id: 'deleteCategoryReminder', defaultMessage: '刪除此分類，其所有子分類亦會被刪除。如有商品(正常和禁用)正使用此分類及其子分類，請先更新商品分類到新分類。' },
    categoryIsStillInUseReminder: { id: 'categoryIsStillInUseReminder', defaultMessage: '有商品(正常和禁用)正使用此分類及其子分類，請先更新商品分類到新分類。' },
    updateManyProductCategory: { id: 'updateManyProductCategory', defaultMessage: '批量更新商品分類' },
    updateManyProductCategorySuccess: { id: 'updateManyProductCategorySuccess', defaultMessage: '批量更新商品分類成功' },
    updateManyProductCategoryFailure: { id: 'updateManyProductCategoryFailure', defaultMessage: '批量更新商品分類失敗' },
    categoryManagementSuggestionText: { id: 'categoryManagementSuggestionText', defaultMessage: '如新分類名稱需使用舊名稱，可暫使用其他新分類名稱，重組商品分類後，將舊分類刪除，最後把新分類名稱更改為你想要的名稱' },
    nameDuplicated: { id: 'nameDuplicated', defaultMessage: '名稱重覆' },
    deliveryTimeSlot: { id: 'deliveryTimeSlot', defaultMessage: '外賣時間' },
    createDeliveryTimeSlot: { id: 'createDeliveryTimeSlot', defaultMessage: '新增外賣時間' },
    createDeliveryTimeSlotSuccess: { id: 'createDeliveryTimeSlotSuccess', defaultMessage: '新增外賣時間成功' },
    createDeliveryTimeSlotFailure: { id: 'createDeliveryTimeSlotFailure', defaultMessage: '新增外賣時間失敗' },
    updateDeliveryTimeSlot: { id: 'updateDeliveryTimeSlot', defaultMessage: '更新外賣時間' },
    updateDeliveryTimeSlotSuccess: { id: 'updateDeliveryTimeSlotSuccess', defaultMessage: '更新外賣時間成功' },
    updateDeliveryTimeSlotFailure: { id: 'updateDeliveryTimeSlotFailure', defaultMessage: '更新外賣時間失敗' },
    updateAvailability: { id: 'updateAvailability', defaultMessage: '更新有效時間' },
    updateAvailabilitySuccess: { id: 'updateAvailabilitySuccess', defaultMessage: '更新有效時間成功' },
    updateAvailabilityFailure: { id: 'updateAvailabilityFailure', defaultMessage: '更新有效時間失敗' },
    pleaseCreateDeliveryTimeSlot: { id: 'pleaseCreateDeliveryTimeSlot', defaultMessage: '請先新增外賣時間' },
    deleteSubchoiceSuccess: { id: 'deleteSubchoiceSuccess', defaultMessage: '刪除子選擇成功' },
    deleteSubchoiceFailure: { id: 'deleteSubchoiceFailure', defaultMessage: '刪除子選擇失敗' },
    subchoiceManagement: { id: 'subchoiceManagement', defaultMessage: '子選擇管理' },
    subchoiceContent: { id: 'subchoiceContent', defaultMessage: '子選擇內容' },
    subchoice: { id: 'subchoice', defaultMessage: '子選擇' },
    subchoiceName: { id: 'subchoiceName', defaultMessage: '子選擇名稱' },
    createSubchoice: { id: 'createSubchoice', defaultMessage: '新增子選擇' },
    editSubchoice: { id: 'editSubchoice', defaultMessage: '更新子選擇' },
    createSubchoiceSuccess: { id: 'createSubchoiceSuccess', defaultMessage: '創建子選擇成功' },
    createSubchoiceFailure: { id: 'createSubchoiceFailure', defaultMessage: '創建子選擇失敗' },
    updateSubchoiceSuccess: { id: 'updateSubchoiceSuccess', defaultMessage: '更新子選擇成功' },
    updateSubchoiceFailure: { id: 'updateSubchoiceFailure', defaultMessage: '更新子選擇失敗' },
    exportOrderReminder: { id: 'exportOrderReminder', defaultMessage: '最少選取一個訂單' },
    deleteDeliveryTimeSlotSuccess: { id: 'deleteDeliveryTimeSlotSuccess', defaultMessage: '刪除外賣時間成功' },
    deleteDeliveryTimeSlotFailure: { id: 'deleteDeliveryTimeSlotFailure', defaultMessage: '刪除外賣時間失敗' },
    pleaseInputShopNameAgain: { id: 'pleaseInputShopNameAgain', defaultMessage: '請再次輸入商店名稱' },
    deleteShopSuccess: { id: 'deleteShopSuccess', defaultMessage: '刪除商店成功' },
    deleteShopFailure: { id: 'deleteShopFailure', defaultMessage: '刪除商店失敗' },
    foodOrder: { id: 'foodOrder', defaultMessage: '外賣訂單' },
    home: { id: 'home', defaultMessage: '主頁' },
    selectedOption: { id: 'selectedOption', defaultMessage: '已選擇選項' },
    priority: { id: 'priority', defaultMessage: '優先次序' },
    orderCountByDay: { id: 'orderCountByDay', defaultMessage: '按日訂單數' },
    orderCountByHour: { id: 'orderCountByHour', defaultMessage: '按小時訂單數' },
    byHour: { id: 'byHour', defaultMessage: '按小時' },
    byDay: { id: 'byDay', defaultMessage: '按日' },
    byMonth: { id: 'byMonth', defaultMessage: '按月' },
    pickCoordinate: { id: 'pickCoordinate', defaultMessage: '選擇座標' },
    deliveryRevenue: { id: 'deliveryRevenue', defaultMessage: '外賣營業額' },
    selfPickUpRevenue: { id: 'selfPickUpRevenue', defaultMessage: '自取營業額' },
    totalRevenue: { id: 'totalRevenue', defaultMessage: '總營業額' },
    amountReceived: { id: 'amountReceived', defaultMessage: '所得金額' },
    searchLocation: { id: 'searchLocation', defaultMessage: '搜尋地點' },
    transactionFee: { id: 'transactionFee', defaultMessage: '交易費用' },
    shopperPlatformFee: { id: 'shopperPlatformFee', defaultMessage: 'Shopper平台費用' },
    memberTotalCountByWeek: { id: 'memberTotalCountByWeek', defaultMessage: '按週會員總數量' },
    updateManyProductOption: { id: 'updateManyProductOption', defaultMessage: '批量更新商品選項' },
    updateManyProductOptionSuccess: { id: 'updateManyProductOptionSuccess', defaultMessage: '批量更新商品選項成功' },
    updateManyProductOptionFailure: { id: 'updateManyProductOptionFailure', defaultMessage: '批量更新商品選項失敗' },
    deliveryFee: { id: 'deliveryFee', defaultMessage: '運費' },
    deliveryFeePerOrder: { id: 'deliveryFeePerOrder', defaultMessage: '每單{deliveryFee}元' },
    orderTracking: { id: 'orderTracking', defaultMessage: '訂單追縱' },
    foodDeliveryMan: { id: 'foodDeliveryMan', defaultMessage: '送餐員' },
    foodDeliveryManName: { id: 'foodDeliveryManName', defaultMessage: '送餐員名稱' },
    foodDeliveryManPhoneNumber: { id: 'foodDeliveryManPhoneNumber', defaultMessage: '送餐員電話' },
    waitingForFoodDeliveryManAcceptingOrder: { id: 'waitingForFoodDeliveryManAcceptingOrder', defaultMessage: '等待送餐員接單' },
    foodDeliveryManConfirmed: { id: 'foodDeliveryManConfirmed', defaultMessage: '送餐員已接單' },
    deliveryInProgress: { id: 'deliveryInProgress', defaultMessage: '外送中' },
    noDeliveryMan: { id: 'noDeliveryMan', defaultMessage: '沒有送餐員' },
    startingPoint: { id: 'startingPoint', defaultMessage: '起點' },
    endingPoint: { id: 'endingPoint', defaultMessage: '終點' },
    deliveryOrderCount: { id: 'deliveryOrderCount', defaultMessage: '外賣訂單數' },
    selfPickUpOrderCount: { id: 'selfPickUpOrderCount', defaultMessage: '自取訂單數' },
    refund: { id: 'refund', defaultMessage: '退款' },
    refunded: { id: 'refunded', defaultMessage: '已退款' },
    refundId: { id: 'refundId', defaultMessage: '退款編號' },
    refundReason: { id: 'refundReason', defaultMessage: '退款原因' },
    reason: { id: 'reason', defaultMessage: '原因' },
    refundSuccess: { id: 'refundSuccess', defaultMessage: '退款成功' },
    refundFailure: { id: 'refundFailure', defaultMessage: '退款失敗' },
    all: { id: 'all', defaultMessage: '全部' },
    refundAmount: { id: 'refundAmount', defaultMessage: '退款金額' },
    amountReceivedAfterRefund: { id: 'amountReceivedAfterRefund', defaultMessage: '退款後所得金額' },
    shopperReport: { id: 'shopperReport', defaultMessage: 'Shopper報告' },
    call4VanReport: { id: 'call4VanReport', defaultMessage: 'Call4Van報告' },
    shopperDeliveryReport: { id: 'shopperDeliveryReport', defaultMessage: 'Shopper外賣報告' },
    shopperBanner: { id: 'shopperBanner', defaultMessage: 'Shopper Banner' },
    createShopperBanner: { id: 'createShopperBanner', defaultMessage: '新增Shopper橫額' },
    editShopperBanner: { id: 'editShopperBanner', defaultMessage: '更新Shopper橫額' },
    createShopperBannerSuccess: { id: 'createShopperBannerSuccess', defaultMessage: '創建Shopper橫額成功' },
    createShopperBannerFailure: { id: 'createShopperBannerFailure', defaultMessage: '創建Shopper橫額失敗' },
    updateShopperBannerSuccess: { id: 'updateShopperBannerSuccess', defaultMessage: '更新Shopper橫額成功' },
    updateShopperBannerFailure: { id: 'updateShopperBannerFailure', defaultMessage: '更新Shopper橫額失敗' },
    deleteShopperBannerSuccess: { id: 'deleteShopperBannerSuccess', defaultMessage: '刪除Shopper橫額成功' },
    deleteShopperBannerFailure: { id: 'deleteShopperBannerFailure', defaultMessage: '刪除Shopper橫額失敗' },
    endDate: { id: 'endDate', defaultMessage: '結束時間' },
    promotionCode: { id: 'promotionCode', defaultMessage: '推廣碼' },
    createPromotionCode: { id: 'createPromotionCode', defaultMessage: '新增推廣碼' },
    editPromotionCode: { id: 'editPromotionCode', defaultMessage: '更新推廣碼' },
    createPromotionCodeSuccess: { id: 'createPromotionCodeSuccess', defaultMessage: '創建推廣碼成功' },
    createPromotionCodeFailure: { id: 'createPromotionCodeFailure', defaultMessage: '創建推廣碼失敗' },
    updatePromotionCodeSuccess: { id: 'updatePromotionCodeSuccess', defaultMessage: '更新推廣碼成功' },
    updatePromotionCodeFailure: { id: 'updatePromotionCodeFailure', defaultMessage: '更新推廣碼失敗' },
    deletePromotionCodeSuccess: { id: 'deletePromotionCodeSuccess', defaultMessage: '刪除推廣碼成功' },
    deletePromotionCodeFailure: { id: 'deletePromotionCodeFailure', defaultMessage: '刪除推廣碼失敗' },
    maxUsagePerUser: { id: 'maxUsagePerUser', defaultMessage: '每位客人最多使用次數' },
    codeMustBeAlphanumeric: { id: 'codeMustBeAlphanumeric', defaultMessage: '推廣碼必須為數字及英文字母' },
    kitchenPrintingEnable: { id: 'kitchenPrintingEnable', defaultMessage: '廚房單打印啟用' },
    deliveryCarrier: { id: 'deliveryCarrier', defaultMessage: '外送平台' },
    emailNotFound: { id: 'emailNotFound', defaultMessage: '電郵不存在' },
    shopperDeliveryOrderExport: { id: 'shopperDeliveryOrderExport', defaultMessage: 'Shopper外賣訂單導出' },
    deliveryCarrierOrderId: { id: 'deliveryCarrierOrderId', defaultMessage: '外送平台訂單號' },
    latestUpdateTime: { id: 'latestUpdateTime', defaultMessage: '最後更新時間' },
    cancelCarrierOrder: { id: 'cancelCarrierOrder', defaultMessage: '取消物流訂單' },
    cancelCarrierOrderSuccess: { id: 'cancelCarrierOrderSuccess', defaultMessage: '取消物流訂單成功' },
    cancelCarrierOrderFailure: { id: 'cancelCarrierOrderFailure', defaultMessage: '取消物流訂單失敗' },
    selfPickUpDiscount: { id: 'selfPickUpDiscount', defaultMessage: '自取折扣' },
    shopFoodOrderSales: { id: 'shopFoodOrderSales', defaultMessage: '商店外賣銷量' },
    createZeekOrder: { id: 'createZeekOrder', defaultMessage: 'Zeek落單' },
    createZeekOrderSuccess: { id: 'createZeekOrderSuccess', defaultMessage: 'Zeek落單成功' },
    createZeekOrderFailure: { id: 'createZeekOrderFailure', defaultMessage: 'Zeek落單失敗' },
    localExpress: { id: 'localExpress', defaultMessage: '本地速遞' },
    isCustomLogistics: { id: 'isCustomLogistics', defaultMessage: '是否商舖自訂物流' },
    customLogisticSetting: { id: 'customLogisticSetting', defaultMessage: '商舖自訂物流設定' },
    freeCustomShippingPrice: { id: 'freeCustomShippingPrice', defaultMessage: '自訂物流免運費金額' },
    nonfreeCustomShippingFee: { id: 'nonfreeCustomShippingFee', defaultMessage: '自訂物流非免運費用' },
    retailReport: { id: 'retailReport', defaultMessage: '零售報告' },
    retailOrder: { id: 'retailOrder', defaultMessage: '零售訂單' },
    restaurantMap: { id: 'restaurantMap', defaultMessage: '餐廳地圖' },
    todaySales: { id: 'todaySales', defaultMessage: '本日銷售' },
    mallNotification: { id: 'mallNotification', defaultMessage: '商城通知' },
    sendMallNotification: { id: 'sendMallNotification', defaultMessage: '發送商城通知' },
    sendMallNotificationSuccess: { id: 'sendMallNotificationSuccess', defaultMessage: '發送商城通知成功' },
    sendMallNotificationFailure: { id: 'sendMallNotificationFailure', defaultMessage: '發送商城通知失敗' },
    updateManyOrderStatus: { id: 'updateManyOrderStatus', defaultMessage: '更新多個訂單狀態' },
    updateManyOrderStatusSuccess: { id: 'updateManyOrderStatusSuccess', defaultMessage: '更新多個訂單狀態成功' },
    updateManyOrderStatusFailure: { id: 'updateManyOrderStatusFailure', defaultMessage: '更新多個訂單狀態失敗' },
    delivering: { id: 'delivering', defaultMessage: '運送中' },
    logisticStatus: { id: 'logisticStatus', defaultMessage: '物流狀態' },
    foodDeliveryManNearStore: { id: 'foodDeliveryManNearStore', defaultMessage: '配送員已到達店付近' },
    zeekOrderType: { id: 'zeekOrderType', defaultMessage: 'Zeek訂單類型' },
    amountDeduction: { id: 'amountDeduction', defaultMessage: '金額減免' },
    discountRate: { id: 'discountRate', defaultMessage: '折扣率' },
    deleteCouponSuccess: { id: 'deleteCouponSuccess', defaultMessage: '刪除優惠劵成功' },
    deleteCouponFailure: { id: 'deleteCouponFailure', defaultMessage: '刪除優惠劵失敗' },
    productStock: { id: 'productStock', defaultMessage: '商品貨存' },
    stockChange: { id: 'stockChange', defaultMessage: '貨存變動' },
    productInformation: { id: 'productInformation', defaultMessage: '商品資料' },
    stockAlert: { id: 'stockAlert', defaultMessage: '貨存預警' },
    memberCredit: { id: 'memberCredit', defaultMessage: '會員積分' },
    compensate: { id: 'compensate', defaultMessage: '補償' },
    compensated: { id: 'compensated', defaultMessage: '已補償' },
    compensationId: { id: 'compensationId', defaultMessage: '補償編號' },
    compensationReason: { id: 'compensationReason', defaultMessage: '補償原因' },
    compensateSuccess: { id: 'compensateSuccess', defaultMessage: '補償成功' },
    compensateFailure: { id: 'compensateFailure', defaultMessage: '補償失敗' },
    compensationAmount: { id: 'compensationAmount', defaultMessage: '補償金額' },
    clonedShop: { id: 'clonedShop', defaultMessage: '複制商店' },
    productsCategoriesOptionsAndDeliveryTimeslotsWillbeCloned: { id: 'productsCategoriesOptionsAndDeliveryTimeslotsWillbeCloned', defaultMessage: '商品、分類、選項和外賣時間會被複制' },
    selectedShopCount: { id: 'selectedShopCount', defaultMessage: '(已選取{count}個商舖)' },
    selectedStoreCount: { id: 'selectedCountStore', defaultMessage: '(已選取{count}個分店)' },
    downloaded: { id: 'downloaded', defaultMessage: '已下載' },
    downloading: { id: 'downloading', defaultMessage: '下載中' },
    downloadedWithCountAndTotal: { id: 'downloadedWithCountAndTotal', defaultMessage: '已下載{count}, 共{total}' },
    shopSummary: { id: 'shopSummary', defaultMessage: '商店概要報告' },
    setting: { id: 'settings', defaultMessage: '設定' },
    extremeWeatherAction: { id: 'extremeWeatherAction', defaultMessage: '極端天氣操作' },
    stopAllStores: { id: 'stopAllStores', defaultMessage: '暫停所有分店外賣' },
    stopStoreReminder: { id: 'allStoreStatusWillbeSetAsInactive', defaultMessage: '現時的分店狀態會被紀錄，所有分店狀態會被設置為禁用' },
    resumeAllStores: { id: 'resumeAllStores', defaultMessage: '恢復所有分店外賣' },
    resumeAllStoreReminder: { id: 'resumeAllStoreReminder', defaultMessage: '所有分店會恢復到暫停前的狀態' },
    success: { id: 'success', defaultMessage: '成功' },
    failure: { id: 'failure', defaultMessage: '失敗' },
    allStoreStatus: { id: 'allStoreStatus', defaultMessage: '所有分店狀態' },
    storeStatus: { id: 'storeStatus', defaultMessage: '分店狀態' },
    restaurantStatusManagement: { id: 'restaurantStatusManagement', defaultMessage: '餐廳狀態管理' },
})
