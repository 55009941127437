import { action, observable, flow } from 'mobx'
import api from '../api'

class AuthStore {
    @observable isSubmitting = false
    @observable error = null
    @observable isResetEmailSent = false

    resetPassword = flow(function* (token, password) {
        this.isSubmitting = true
        try {
            yield api.resetPassword(token, password)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new AuthStore()
