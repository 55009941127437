import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL

async function resetPassword(token, password) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/user/resetPassword`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            password
        }
    }
    return axios(options)
}

export default {
    resetPassword
}